@import "./screens";

@mixin mobile-small {
  @media only screen and (max-width: $screen-xxxs) {
    @content;
  }
}
@mixin mobile {
  @media only screen and (max-width: $screen-sm) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
    @content;
  }
}

@mixin tablet-big {
  @media only screen and (min-width: $screen-md) and (max-width: $screen-lg) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (max-width: $screen-lg) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $screen-lg) and (max-width: $screen-xl) {
    @content;
  }
}
@mixin desktop-big {
  @media only screen and (min-width: $screen-xl) and (max-width: $screen-xxl) {
    @content;
  }
}

@mixin screen-xxl {
  @media only screen and (min-width: $screen-xxl) and (max-width: $screen-2k) {
    @content;
  }
}
@mixin screen-2k {
  @media only screen and (min-width: $screen-2k) {
    @content;
  }
}
@mixin screen-4k {
  @media only screen and (min-width: $screen-4k) {
    @content;
  }
}

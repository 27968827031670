.PageNotFound {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageNotFound__message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PageNotFound__number {
  font-size: 128px;
  font-weight: bold;
  color: #1e2945;
}
.PageNotFound__number span {
  color: #77829e;
}
.PageNotFound__label {
  font-size: 24px;
  color: #1e2945;
}

.LanguageSelect {
/*   margin-bottom: 10px; */
  position: relative;
 /*  width: 145px; */
 width: 100px;
}

.LanguageSelect__head {
    padding: 10px; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.LanguageSelect__head:hover {
    cursor: pointer;
}
.LanguageSelect__head-flag {
    width: 32px;
    height: 20px;
    border-radius: 3px;
    margin-right: 10px;
}
.LanguageSelect__head-label {
    font-size: 16px;
    color: #111827;
}
.LanguageSelect__head-chevron {
    width: 11px;
    margin-left: 10px;
}

.LanguageSelect__body {
    width: 100%;
    position: absolute;
    top: 0;
    background-color: #f0f4ff;
    border: 1px solid #77A3DB;
    border-radius: 4px; 
}
.LanguageSelect__body-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    transition: all 300ms ease-in-out;
}
.LanguageSelect__body-item:hover {
    cursor: pointer;
    background-color: #77A3DB;
}
.LanguageSelect__body-item:hover .LanguageSelect__body-label {
    color: #ffffff;
}
.LanguageSelect__body-flag {
    width: 32px;
    height: 20px;
    border-radius: 3px;
    margin-right: 10px;
}

.LanguageSelect__body-label {
    font-size: 16px;
    color: #111827;
    transition: all 300ms ease-in-out;
}
@import url("https://fonts.cdnfonts.com/css/proxima-nova-2");
@import "src/styles/mixins";

.Home {
  &__container {
    display: flex;
    justify-content: center;

    &-backgroundImage {
      position: fixed;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    &-blurBackgroundImage {
      position: fixed;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }
}

.Container {
  display: flex;
  z-index: 2;
  padding: 50px;
  &__left {
    background-color: rgba(255, 246, 234, 1);
    width: 480px;
    height: 583px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;

    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 32px 40px 32px;
    }

    &-image {
      padding-left: 32px;
    }
    &-title {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      padding: 30px 32px 0 32px;
    }
    &-content {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      padding: 17px 22px 0 33px;
    }
    &-bottom-img {
      width: 68px;
      height: 68px;
      padding: 30px 0 0 33px;
    }
  }

  &__right {
    background-color: rgba(21, 30, 55, 1);
    width: 480px;
    height: 583px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-paragraph {
      color: white;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    &-button {
      background-color: #76a3da;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      padding: 15px 25px;

      &:hover {
        cursor: pointer;
        background-color: darken(#76a3da, 10%);
      }

      &--disabled {
        background-color: gray;

        &:hover {
          cursor: not-allowed;
          background-color: gray;
        }
      }

      &--icon {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }

      &--label {
        color: white;
      }
    }
  }
}

.Message {
  z-index: 2;
  background-color: #fef6ea;
  border-radius: 20px;
  padding: 10px 50px 20px 50px;
  margin: 50px 20px;

  &__title {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.spinner:after {
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  margin: 0;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff #fff;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include mobile {
  .Container {
    display: flex;
    flex-direction: column;
    padding: 0;

    &__left {
      border-radius: 0;
      width: 100%;
    }
    &__right {
      border-radius: 0;
      width: 100%;
    }
  }
}
@include tablet {
  .Container {
    display: flex;
    flex-direction: column;

    &__left {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      width: 100%;
    }
    &__right {
      border-top-right-radius: 0;
      border-bottom-left-radius: 20px;
      width: 100%;
    }
  }
}
